<template>
  <div>
    <div>
      <span>{{ (Math.round(amount * 100) / 100) }}</span>
      <button @click="btnClickedHandler()" class="svg"><div><v-svg>doc</v-svg></div></button>
    </div>
    <div>
      <v-modal class="toto" v-if="showModal">
        <h3 slot="header">{{ $t('entry.modal.warning') }} !</h3>
        <span slot="body">warningMessage</span>
        <v-button slot="footer" class="small" @click="showModal=false">{{ $t('entry.modal.continue') }}</v-button>
      </v-modal>
    </div>
  </div>
</template>

<script>
    export default {
        name: "cellRenderer",
        data() {
          return {
            amount: '',
            showModal: false,
          }
        },
        methods: {
          refresh(params) {
            this.params = params
            this.setMood(params)
          },

          btnClickedHandler() {
            this.params.clicked(this.params);
          },

          setMood(params) {
            this.amount = params.value
          }
        },
        created() {
          this.setMood(this.params)
        }
    }
</script>

<style scoped>
  .svg {
    background-color: transparent;
    color: darkgrey;
    border-radius: 1.6rem 0 0 1.6rem;
    height: 2.2rem;
    border: none;
    float: right;
    cursor: pointer;
  }
</style>
